const { protocol, hostname, port } = window.location;

const {
  NODE_ENV,
  PUBLIC_URL,
  REACT_APP_ENVIRONMENT,
  REACT_APP_GRAPHQL_URL,
  REACT_APP_WEBSOCKET_URL,
  REACT_APP_AUTH0_DOMAIN,
  REACT_APP_AUTH0_CLIENT_ID,
  REACT_APP_AUTH0_AUDIENCE,
  REACT_APP_SENTRY_DSN,
  REACT_APP_OPTIMIZELY_SDK_KEY,
} = process.env;

export const {
  nodeEnv = NODE_ENV,
  publicUrl = PUBLIC_URL,
  ENVIRONMENT: environment = REACT_APP_ENVIRONMENT,
  RELAY_GRAPHQL_URL: relayGraphqlUrl = REACT_APP_GRAPHQL_URL || "/graphql",
  RELAY_WEBSOCKET_URL: relayWebsocketUrl = REACT_APP_WEBSOCKET_URL ||
    `${protocol === "http:" ? "ws" : "wss"}://${[
      hostname,
      nodeEnv === "development" ? "3001" : port,
    ].join(":")}/graphql`,
  AUTH0_DOMAIN: auth0Domain = REACT_APP_AUTH0_DOMAIN,
  AUTH0_CLIENT_ID: auth0ClientId = REACT_APP_AUTH0_CLIENT_ID,
  AUTH0_AUDIENCE: auth0Audience = REACT_APP_AUTH0_AUDIENCE,
  OPTIMIZELY_SDK_KEY: optimizelySdkKey = REACT_APP_OPTIMIZELY_SDK_KEY,
  SENTRY_DSN: sentryDsn = REACT_APP_SENTRY_DSN,
} = window;
